import utilActions from '@/api/actions';

const notifyAllDocsSubmitted = {
	id: 'notifyAllDocsSubmitted',
	selectionType: 'single',
	label: 'notifyAllDocsSubmitted.label',
	functionality: 'UPDATE_PORTCALLS',
	checkAvailability: function (row) {
		if (
			row[0] &&
			(row[0].arrimmigrationstatus == 'DOC_PENDING' ||
				row[0].arrimmigrationstatus == 'DOC_REJECTED' ||
				row[0].arrcustomsstatus == 'DOC_PENDING' ||
				row[0].arrcustomsstatus == 'DOC_REJECTED' ||
				row[0].arrharbourmasterstatus == 'DOC_PENDING' ||
				row[0].arrharbourmasterstatus == 'DOC_REJECTED') &&
			row[0].status != 0 &&
			row[0].status != 3
		) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.remarks = registries[0].remarks;
		row.pk = registries[0].portcallid;

		this.$puiEvents.$emit('pui-modalDialogForm-notifyAllDocsSubmitted-' + model.name + '-show', row);
	}
};

const onOkarrimmireqcheck = {
	id: 'arrimmireqcheck',
	selectionType: 'single',
	label: 'action.arrimmireqcheck',
	functionality: 'IMMIGRATION_REQUESTCHECK',

	checkAvailability: function (row) {
		if (
			row[0] &&
			((row[0].arrimmigrationstatus == 'DOC_VALIDATED' && row[0].portcallstatusid == '2') ||
				(row[0].arrimmigrationstatus == 'DOC_VALIDATED' && row[0].portcallstatusid == '4') ||
				(row[0].arrimmigrationstatus == 'DOC_VALIDATED' && row[0].portcallstatusid == '5')) &&
			row[0].status != 0
		) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-arrimmireqcheck-' + model.name + '-show', row);
	}
};

const onOkdepimmireqcheck = {
	id: 'depimmireqcheck',
	selectionType: 'single',
	label: 'action.depimmireqcheck',
	functionality: 'IMMIGRATION_REQUESTCHECK',

	checkAvailability: function (row) {
		if (row && row[0] && row[0].depimmigrationstatus == 'DOC_VALIDATED' && row[0].status != 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-depimmireqcheck-' + model.name + '-show', row);
	}
};

const onOkarrimmiconfirmface = {
	id: 'arrimmiconfirmface',
	selectionType: 'single',
	label: 'action.arrimmiconfirmface',
	functionality: 'IMMIGRATION_CONFIRMFACETOFACECHECK',

	checkAvailability: function (row) {
		if (row && row[0] && row[0].arrimmigrationstatus == 'CHECK_REQUESTED' && row[0].status != 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.date = registries[0].date;
		row.pk = registries[0].portcallid;
		this.$puiEvents.$emit('pui-modalDialogForm-arrimmiconfirmface-' + model.name + '-show', row);
	}
};

const onOkdepimmiconfirmface = {
	id: 'depimmiconfirmface',
	selectionType: 'single',
	label: 'action.depimmiconfirmface',
	functionality: 'IMMIGRATION_CONFIRMFACETOFACECHECK',

	checkAvailability: function (row) {
		if (row && row[0] && row[0].depimmigrationstatus == 'CHECK_REQUESTED' && row[0].status != 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.date = registries[0].date;
		row.pk = registries[0].portcallid;
		this.$puiEvents.$emit('pui-modalDialogForm-depimmiconfirmface-' + model.name + '-show', row);
	}
};

const onOkarrimmiconfirmclear = {
	id: 'arrimmiconfirmclear',
	selectionType: 'single',
	label: 'action.arrimmiconfirmclear',
	functionality: 'IMMIGRATION_CONFIRMCLEARANCE',

	checkAvailability: function (row) {
		if (
			row &&
			row[0] &&
			(row[0].arrimmigrationstatus == 'CHECK_REQUESTED' || row[0].arrimmigrationstatus == 'CHECK_CONFIRMED') &&
			row[0].status != 0
		) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-arrimmiconfirmclear-' + model.name + '-show', row);
	}
};

const onOkdepimmiconfirmclear = {
	id: 'depimmiconfirmclear',
	selectionType: 'single',
	label: 'action.depimmiconfirmclear',
	functionality: 'IMMIGRATION_CONFIRMCLEARANCE',

	checkAvailability: function (row) {
		if (
			row &&
			row[0] &&
			(row[0].depimmigrationstatus == 'CHECK_REQUESTED' || row[0].depimmigrationstatus == 'CHECK_CONFIRMED') &&
			row[0].status != 0
		) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-depimmiconfirmclear-' + model.name + '-show', row);
	}
};

const depnotifyAllDocsSubmitted = {
	id: 'depnotifyAllDocsSubmitted',
	selectionType: 'single',
	label: 'depnotifyAllDocsSubmitted.label',
	functionality: 'UPDATE_PORTCALLS',
	checkAvailability: function (row) {
		if (
			row[0] &&
			(row[0].arrimmigrationstatus == 'DOC_PENDING' ||
				row[0].depimmigrationstatus == 'DOC_REJECTED' ||
				row[0].depcustomsstatus == 'DOC_PENDING' ||
				row[0].depcustomsstatus == 'DOC_REJECTED' ||
				row[0].depharbourmasterstatus == 'DOC_PENDING' ||
				row[0].depharbourmasterstatus == 'DOC_REJECTED') &&
			row[0].status != 0 &&
			row[0].status != 3
		) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.remarks = registries[0].remarks;
		row.pk = registries[0].portcallid;
		this.$puiEvents.$emit('pui-modalDialogForm-depnotifyAllDocsSubmitted-' + model.name + '-show', row);
	}
};

const onOkArrImmigrationValidateDocs = {
	id: 'arrvalidatedocs',
	selectionType: 'single',
	label: 'arrvalidatedocs.title',
	functionality: 'IMMIGRATION_VALIDATEDOCUMANTATION',
	checkAvailability: function (row) {
		if (row[0] && row[0].arrimmigrationstatus == 'DOC_READY' && row[0].status != 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-arrvalidatedocs-' + model.name + '-show', row);
	}
};

const onOkDepImmigrationValidateDocs = {
	id: 'depvalidatedocs',
	selectionType: 'single',
	label: 'depvalidatedocs.title',
	functionality: 'IMMIGRATION_VALIDATEDOCUMANTATION',
	checkAvailability: function (row) {
		if (row[0] && row[0].depimmigrationstatus == 'DOC_READY' && row[0].status != 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-depvalidatedocs-' + model.name + '-show', row);
	}
};

const arrImmigrationRejectDocs = {
	id: 'arrimmirejectdocs',
	selectionType: 'single',
	label: 'arrimmirejectdocs.title',
	functionality: 'IMMIGRATION_REJECTDOCUMENTATION',
	checkAvailability: function (row) {
		if (row[0] && row[0].arrimmigrationstatus == 'DOC_READY' && row[0].status != 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.remarks = registries[0].remarks;
		row.pk = registries[0].portcallid;

		this.$puiEvents.$emit('pui-modalDialogForm-arrimmirejectdocs-' + model.name + '-show', row);
	}
};

const depImmigrationRejectDocs = {
	id: 'depimmirejectdocs',
	selectionType: 'single',
	label: 'depimmirejectdocs.title',
	functionality: 'IMMIGRATION_REJECTDOCUMENTATION',
	checkAvailability: function (row) {
		if (row[0] && row[0].depimmigrationstatus == 'DOC_READY' && row[0].status != 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.remarks = registries[0].remarks;
		row.pk = registries[0].portcallid;

		this.$puiEvents.$emit('pui-modalDialogForm-depimmirejectdocs-' + model.name + '-show', row);
	}
};

const onOkarrcustacceptdocs = {
	id: 'arrcustacceptdocs',
	selectionType: 'single',
	label: 'action.arrcustacceptdocs',
	functionality: 'CUSTOMS_VALIDATEREJECTDOCS',

	checkAvailability: function (row) {
		if (row && row[0] && row[0].arrcustomsstatus == 'DOC_READY' && row[0].status != 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-arrcustacceptdocs-' + model.name + '-show', row);
	}
};

const onOkdepcustacceptdocs = {
	id: 'depcustacceptdocs',
	selectionType: 'single',
	label: 'action.depcustacceptdocs',
	functionality: 'CUSTOMS_VALIDATEREJECTDOCS',

	checkAvailability: function (row) {
		if (row && row[0] && row[0].depcustomsstatus == 'DOC_READY' && row[0].status != 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-depcustacceptdocs-' + model.name + '-show', row);
	}
};

const onOkarrcustRejectDocs = {
	id: 'arrcustrejectdocs',
	selectionType: 'single',
	label: 'action.arrcustrejectdocs',
	functionality: 'CUSTOMS_VALIDATEREJECTDOCS',
	checkAvailability: function (row) {
		if (row[0] && row[0].arrcustomsstatus == 'DOC_READY' && row[0].status != 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.remarks = registries[0].remarks;
		row.pk = registries[0].portcallid;

		this.$puiEvents.$emit('pui-modalDialogForm-arrcustrejectdocs-' + model.name + '-show', row);
	}
};

const onOKdepcustRejectDocs = {
	id: 'depcustrejectdocs',
	selectionType: 'single',
	label: 'action.depcustrejectdocs',
	functionality: 'CUSTOMS_VALIDATEREJECTDOCS',
	checkAvailability: function (row) {
		if (row[0] && row[0].depcustomsstatus == 'DOC_READY' && row[0].status != 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.remarks = registries[0].remarks;
		row.pk = registries[0].portcallid;

		this.$puiEvents.$emit('pui-modalDialogForm-depcustrejectdocs-' + model.name + '-show', row);
	}
};

const onOKcustRejectBerth = {
	id: 'custrejectberth',
	selectionType: 'single',
	label: 'action.custrejectberth',
	functionality: 'CUSTOMS_VALIDATEREJECTDOCS',
	checkAvailability: function (row) {
		if (row[0] && row[0].arrcustomsstatus != 'REJECTED' && row[0].status != 0 && row[0].status != 3) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.remarks = registries[0].remarks;
		row.pk = registries[0].portcallid;

		this.$puiEvents.$emit('pui-modalDialogForm-custrejectberth-' + model.name + '-show', row);
	}
};

const onOkarrharborRejectDocs = {
	id: 'arrharborrejectdocs',
	selectionType: 'single',
	label: 'action.arrharborrejectdocs',
	functionality: 'HARBOR_MASTER_CLEARANCE',
	checkAvailability: function (row) {
		if (row[0] && row[0].arrharbourmasterstatus == 'DOC_READY' && row[0].status != 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.remarks = registries[0].remarks;
		row.pk = registries[0].portcallid;

		this.$puiEvents.$emit('pui-modalDialogForm-arrharborrejectdocs-' + model.name + '-show', row);
	}
};

const onOKdepharborRejectDocs = {
	id: 'depharborrejectdocs',
	selectionType: 'single',
	label: 'action.depharborrejectdocs',
	functionality: 'HARBOR_MASTER_CLEARANCE',
	checkAvailability: function (row) {
		if (row[0] && row[0].depharbourmasterstatus == 'DOC_READY' && row[0].status != 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.remarks = registries[0].remarks;
		row.pk = registries[0].portcallid;

		this.$puiEvents.$emit('pui-modalDialogForm-depharborrejectdocs-' + model.name + '-show', row);
	}
};

const onOkarrharborconfirmclear = {
	id: 'arrharborconfirmclear',
	selectionType: 'single',
	label: 'action.arrharborconfirmclear',
	functionality: 'HARBOR_MASTER_CLEARANCE',

	checkAvailability: function (row) {
		if (row && row[0] && row[0].arrharbourmasterstatus == 'DOC_READY' && row[0].status != 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-arrharborconfirmclear-' + model.name + '-show', row);
	}
};

const onOkdepharborconfirmclear = {
	id: 'depharborconfirmclear',
	selectionType: 'single',
	label: 'action.depharborconfirmclear',
	functionality: 'HARBOR_MASTER_CLEARANCE',

	checkAvailability: function (row) {
		if (
			(row &&
				row[0] &&
				row[0].depharbourmasterstatus == 'DOC_VALIDATED' &&
				row[0].depcustomsstatus == 'CLEARED' &&
				row[0].depimmigrationstatus == 'CLEARED' &&
				row[0].portcallstatusid == '8' &&
				row[0].status != 0) ||
			(row &&
				row[0] &&
				row[0].depharbourmasterstatus == 'DOC_VALIDATED' &&
				row[0].depcustomsstatus == 'CLEARED' &&
				row[0].depimmigrationstatus == 'CLEARED' &&
				row[0].portcallstatusid == '3' &&
				row[0].status != 0)
		) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-depharborconfirmclear-' + model.name + '-show', row);
	}
};

const onOkdepharborvalidatedocs = {
	id: 'depharborvalidatedocs',
	selectionType: 'single',
	label: 'action.depharborvalidatedocs',
	functionality: 'HARBOR_MASTER_CLEARANCE',

	checkAvailability: function (row) {
		if (row[0] && row[0].depharbourmasterstatus == 'DOC_READY' && row[0].status != 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-depharborvalidatedocs-' + model.name + '-show', row);
	}
};

export default {
	actions: [
		notifyAllDocsSubmitted,
		onOkArrImmigrationValidateDocs,
		onOkarrimmireqcheck,
		arrImmigrationRejectDocs,
		onOkarrimmiconfirmclear,
		onOkarrimmiconfirmface,
		onOkarrcustacceptdocs,
		onOkarrcustRejectDocs,
		onOkdepimmiconfirmface,
		onOkdepimmiconfirmclear,
		depnotifyAllDocsSubmitted,
		onOkDepImmigrationValidateDocs,
		onOkdepimmireqcheck,
		depImmigrationRejectDocs,
		onOkdepcustacceptdocs,
		onOKdepcustRejectDocs,
		onOKcustRejectBerth,
		onOkarrharborRejectDocs,
		onOKdepharborRejectDocs,
		onOkarrharborconfirmclear,
		onOkdepharborconfirmclear,
		onOkdepharborvalidatedocs
	]
};
