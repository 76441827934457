<template>
	<div>
		<pui-modal-dialog-form
			:titleText="$t('modal.arrimmireqcheck.title')"
			:modelName="modelName"
			:dialogName="dialogNamearrimmireqcheck"
			:onOk="onOkarrimmireqcheck"
			v-model="modalData"
			:widthDialog="800"
			:overflow="false"
		>
			<template slot="message">
				<v-container>{{ $t('modal.arrimmireqcheck.text') }}</v-container>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.depimmireqcheck.title')"
			:modelName="modelName"
			:dialogName="dialogNamedepimmireqcheck"
			:onOk="onOkdepimmireqcheck"
			v-model="modalData"
			:widthDialog="800"
			:overflow="false"
		>
			<template slot="message">
				<v-container>{{ $t('modal.depimmireqcheck.text') }}</v-container>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.arrimmiconfirmface.title')"
			:modelName="modelName"
			:dialogName="dialogNamearrimmiconfirmface"
			:onOk="onOkarrimmiconfirmface"
			v-model="modalData"
			:widthDialog="800"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12 sm6 class="mt-4">
						<pui-date-field
							:id="`inivalidity${idSufix}`"
							:label="$t('modal.arrimmiconfirmface.text')"
							v-model="data.modalData.date"
							required
							toplabel
							time
							:min="new Date()"
						></pui-date-field>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.depimmiconfirmface.title')"
			:modelName="modelName"
			:dialogName="dialogNamedepimmiconfirmface"
			:onOk="onOkdepimmiconfirmface"
			v-model="modalData"
			:widthDialog="800"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12 sm6 class="mt-4">
						<pui-date-field
							:id="`inivalidity${idSufix}`"
							:label="$t('modal.depimmiconfirmface.text')"
							v-model="data.modalData.date"
							required
							toplabel
							time
							:min="new Date()"
						></pui-date-field>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.arrimmiconfirmclear.title')"
			:modelName="modelName"
			:dialogName="dialogNamearrimmiconfirmclear"
			:onOk="onOkarrimmiconfirmclear"
			v-model="modalData"
			:widthDialog="800"
			:overflow="false"
		>
			<template slot="message">
				<v-container>{{ $t('modal.arrimmiconfirmclear.text') }}</v-container>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.depimmiconfirmclear.title')"
			:modelName="modelName"
			:dialogName="dialogNamedepimmiconfirmclear"
			:onOk="onOkdepimmiconfirmclear"
			v-model="modalData"
			:widthDialog="800"
			:overflow="false"
		>
			<template slot="message">
				<v-container>{{ $t('modal.arrimmiconfirmclear.text') }}</v-container>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('action.notifyAllDocsSubmitted')"
			:modelName="modelName"
			:dialogName="dialogNamenotifyAllDocsSubmitted"
			:widthDialog="800"
			:onOk="onOknotifyAllDocsSubmitted"
			:onShow="onShownotifyAllDocsSubmitted"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-field-set :title="getTitleAutoritiestonotify">
							<v-layout wrap style="margin-top: 15px; margin-bottom: 15px">
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-immigration-${modelName}`"
										:label="$t('modal.alldocumentsSubmitted.checkimmigration')"
										v-model="checkboxGroup.immigration"
										true-value="1"
										false-value="0"
										:disabled="checkimmigration"
									></pui-checkbox
								></v-flex>
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-customs-${modelName}`"
										:label="$t('modal.alldocumentsSubmitted.checkcustoms')"
										v-model="checkboxGroup.customs"
										true-value="1"
										false-value="0"
										:disabled="checkcustoms"
									></pui-checkbox
								></v-flex>
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-harbour-master-${modelName}`"
										:label="$t('modal.alldocumentsSubmitted.checkharbour')"
										v-model="checkboxGroup.harbourMaster"
										true-value="1"
										false-value="0"
										:disabled="checkharbour"
									></pui-checkbox
								></v-flex>
							</v-layout>
						</pui-field-set>
						<v-flex xs12 md12 style="margin-top: 15px">
							<pui-text-area
								:id="`imofalform1menu-observations`"
								v-model="data.modalData.remarks"
								:label="$t('modal.alldocumentsSubmitted.text')"
								toplabel
								maxlength="256"
							></pui-text-area>
						</v-flex>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('action.depnotifyAllDocsSubmitted')"
			:modelName="modelName"
			:dialogName="dialogNameDepnotifyAllDocsSubmitted"
			:widthDialog="800"
			:onOk="onOkdepnotifyAllDocsSubmitted"
			:onShow="onShowdepnotifyAllDocsSubmitted"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-field-set :title="getTitleAutoritiestonotify">
							<v-layout wrap style="margin-top: 15px; margin-bottom: 15px">
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-immigration-${modelName}`"
										:label="$t('modal.alldocumentsSubmitted.checkimmigration')"
										v-model="checkboxGroup.immigration"
										true-value="1"
										false-value="0"
										:disabled="checkimmigration"
									></pui-checkbox
								></v-flex>
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-customs-${modelName}`"
										:label="$t('modal.alldocumentsSubmitted.checkcustoms')"
										v-model="checkboxGroup.customs"
										true-value="1"
										false-value="0"
										:disabled="checkcustoms"
									></pui-checkbox
								></v-flex>
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-harbour-master-${modelName}`"
										:label="$t('modal.alldocumentsSubmitted.checkharbour')"
										v-model="checkboxGroup.harbourMaster"
										true-value="1"
										false-value="0"
										:disabled="checkharbour"
									></pui-checkbox
								></v-flex>
							</v-layout>
						</pui-field-set>
						<v-flex xs12 md12 style="margin-top: 15px">
							<pui-text-area
								:id="`imofalform1menu-observations`"
								v-model="data.modalData.remarks"
								:label="$t('modal.alldocumentsSubmitted.text')"
								toplabel
								maxlength="256"
							></pui-text-area
						></v-flex>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:overflow="false"
			:modelName="modelName"
			:onOk="onOkArrImmigrationValidateDocs"
			:dialogName="arrImmigrationValidateDocs"
			:titleText="$t('action.immigrationValidateDocs')"
			:widthDialog="800"
			v-model="modalData"
		>
			<template slot="message">
				<v-container>{{ $t('arrvalidatedocs.label') }}</v-container>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:overflow="false"
			:modelName="modelName"
			:onOk="onOkDepImmigrationValidateDocs"
			:dialogName="dialogNameDepImmigrationValidateDocs"
			:titleText="$t('action.depimmigrationValidateDocs')"
			:widthDialog="800"
			v-model="modalData"
		>
			<template slot="message">
				<v-container>{{ $t('depvalidatedocs.label') }}</v-container>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('action.arrimmirejectdocs')"
			:modelName="modelName"
			:dialogName="dialogNamearrImmigrationRejectDocs"
			:widthDialog="800"
			:onOk="onOkArrImmigrationRejectDocs"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-text-area
							:id="`imofalform1menu-observations`"
							v-model="data.modalData.remarks"
							:label="$t('arrimmirejectdocs.label')"
							toplabel
							maxlength="256"
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('action.depimmirejectdocs')"
			:modelName="modelName"
			:dialogName="dialogNameDepImmigrationRejectDocs"
			:widthDialog="800"
			:onOk="onOkDepImmigrationRejectDocs"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-text-area
							:id="`imofalform1menu-observations`"
							v-model="data.modalData.remarks"
							:label="$t('depimmirejectdocs.label')"
							toplabel
							required
							maxlength="256"
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.arrcustacceptdocs.title')"
			:modelName="modelName"
			:dialogName="dialogNamearrcustacceptdocs"
			:onOk="onOkarrcustacceptdocs"
			v-model="modalData"
			:widthDialog="800"
			:overflow="false"
		>
			<template slot="message">
				<v-container>{{ $t('modal.arrcustacceptdocs.text') }}</v-container>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.depcustacceptdocs.title')"
			:modelName="modelName"
			:dialogName="dialogNamedepcustacceptdocs"
			:onOk="onOkdepcustacceptdocs"
			v-model="modalData"
			:widthDialog="800"
			:overflow="false"
		>
			<template slot="message">
				<v-container>{{ $t('modal.depcustacceptdocs.text') }}</v-container>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('action.arrcustrejectdocs')"
			:modelName="modelName"
			:dialogName="dialogNamearrCustomRejectDocs"
			:widthDialog="800"
			:onOk="onOkArrCustomRejectDocs"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-text-area
							:id="`imofalform1menu-observations`"
							v-model="data.modalData.remarks"
							:label="$t('arrcustrejectdocs.label')"
							toplabel
							maxlength="256"
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('action.depcustrejectdocs')"
			:modelName="modelName"
			:dialogName="dialogNameDepcustomRejectDocs"
			:widthDialog="800"
			:onOk="onOkDepCustomRejectDocs"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-text-area
							:id="`imofalform1menu-observations`"
							v-model="data.modalData.remarks"
							:label="$t('depcustrejectdocs.label')"
							toplabel
							required
							maxlength="256"
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('action.custrejectberth')"
			:modelName="modelName"
			:dialogName="dialogNameCustomRejectBerth"
			:widthDialog="800"
			:onOk="onOkCustomRejectBerth"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-text-area
							:id="`imofalform1menu-observations`"
							v-model="data.modalData.remarks"
							:label="$t('arrcustrejectdocs.label')"
							toplabel
							maxlength="256"
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('action.arrharborrejectdocs')"
			:modelName="modelName"
			:dialogName="dialogNamearrHarborRejectDocs"
			:widthDialog="800"
			:onOk="onOkArrHarborRejectDocs"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-text-area
							:id="`imofalform1menu-observations`"
							v-model="data.modalData.remarks"
							:label="$t('arrharborrejectdocs.label')"
							toplabel
							required
							maxlength="256"
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('action.depharborrejectdocs')"
			:modelName="modelName"
			:dialogName="dialogNameDepHarborRejectDocs"
			:widthDialog="800"
			:onOk="onOkDepHarborRejectDocs"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-text-area
							:id="`imofalform1menu-observations`"
							v-model="data.modalData.remarks"
							:label="$t('depharborrejectdocs.label')"
							toplabel
							required
							maxlength="256"
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('arrharborconfirmclear.title')"
			:modelName="modelName"
			:dialogName="dialogNamearrharborconfirmclear"
			:onOk="onOkarrharborconfirmclear"
			v-model="modalData"
			:widthDialog="800"
			:overflow="false"
		>
			<template slot="message">
				<v-container>{{ $t('arrharborconfirmclear.label') }}</v-container>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.depharborconfirmclear.title')"
			:modelName="modelName"
			:dialogName="dialogNamedepharborconfirmclear"
			:onOk="onOkdepharborconfirmclear"
			v-model="modalData"
			:widthDialog="800"
			:overflow="false"
		>
			<template slot="message">
				<v-container>{{ $t('modal.depharborconfirmclear.text') }}</v-container>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.depharborvalidatedocs.title')"
			:modelName="modelName"
			:dialogName="dialogNamedepharborvalidatedocs"
			:onOk="onOkdepharborvalidatedocs"
			v-model="modalData"
			:widthDialog="800"
			:overflow="false"
		>
			<template slot="message">
				<v-container>{{ $t('modal.depharborvalidatedocs.text') }}</v-container>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'portcallclearancesstatus-modals',
	data() {
		return {
			arrimmireqcheck: 'arrimmireqcheck',
			depimmireqcheck: 'depimmireqcheck',
			arrimmiconfirmface: 'arrimmiconfirmface',
			depimmiconfirmface: 'depimmiconfirmface',
			arrimmiconfirmclear: 'arrimmiconfirmclear',
			depimmiconfirmclear: 'depimmiconfirmclear',
			dialogNamearrimmireqcheck: 'arrimmireqcheck',
			dialogNamedepimmireqcheck: 'depimmireqcheck',
			dialogNamearrimmiconfirmface: 'arrimmiconfirmface',
			dialogNamedepimmiconfirmface: 'depimmiconfirmface',
			dialogNamearrimmiconfirmclear: 'arrimmiconfirmclear',
			dialogNamearrcustacceptdocs: 'arrcustacceptdocs',
			dialogNamedepcustacceptdocs: 'depcustacceptdocs',
			dialogNamedepimmiconfirmclear: 'depimmiconfirmclear',
			modalData: {},
			model: 'portcallclearancesstatus',
			idSufix: `-${this.modelName}-modal`,
			dialogNamenotifyAllDocsSubmitted: 'notifyAllDocsSubmitted',
			dialogNameDepnotifyAllDocsSubmitted: 'depnotifyAllDocsSubmitted',
			dialogNameDepImmigrationValidateDocs: 'depvalidatedocs',
			dialogNamearrImmigrationRejectDocs: 'arrimmirejectdocs',
			dialogNameDepImmigrationRejectDocs: 'depimmirejectdocs',
			dialogNamearrCustomRejectDocs: 'arrcustrejectdocs',
			dialogNameDepcustomRejectDocs: 'depcustrejectdocs',
			dialogNameCustomRejectBerth: 'custrejectberth',
			notifyAllDocsSubmitted: 'notifyAllDocsSubmitted',
			depnotifyAllDocsSubmitted: 'depnotifyAllDocsSubmitted',
			arrImmigrationValidateDocs: 'arrvalidatedocs',
			depImmigrationValidateDocs: 'depvalidatedocs',
			arrImmigrationRejectDocs: 'arrimmirejectdocs',
			depImmigrationRejectDocs: 'depimmirejectdocs',
			arrCustomRejectDocs: 'arrcustrejectdocs',
			depCustomRejectDocs: 'depcustrejectdocs',
			dialogNamearrHarborRejectDocs: 'arrharborrejectdocs',
			dialogNameDepHarborRejectDocs: 'depharborrejectdocs',
			arrharborconfirmclear: 'arrharborconfirmclear',
			depharborconfirmclear: 'depharborconfirmclear',
			dialogNamearrharborconfirmclear: 'arrharborconfirmclear',
			dialogNamedepharborconfirmclear: 'depharborconfirmclear',
			dialogNamedepharborvalidatedocs: 'depharborvalidatedocs',
			checkboxGroup: {
				immigration: false,
				customs: false,
				harbourMaster: false
			},
			maxCheckboxes: 3,
			modelPortcalls: 'portcalls',
			checkimmigration: true,
			checkcustoms: true,
			checkharbour: true
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onOkarrimmireqcheck(modalData) {
			const urlController = this.$store.getters.getModelByName(this.modelName).url.arrimmireqcheck;
			const title = this.$puiI18n.t('arrimmireqcheck.title');
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid
			};

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}

						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkdepimmireqcheck(modalData) {
			const urlController = this.$store.getters.getModelByName(this.modelName).url.depimmireqcheck;
			const title = this.$puiI18n.t('arrvalidatedocs.title');
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid
			};
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}

						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkarrimmiconfirmface(modalData) {
			const urlController = this.$store.getters.getModelByName(this.modelName).url.arrimmiconfirmface;
			const title = this.$puiI18n.t('arrimmiconfirmface.title');
			const zoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid,
				date: modalData.date,
				zoneid: zoneId
			};
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}

						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkdepimmiconfirmface(modalData) {
			const urlController = this.$store.getters.getModelByName(this.modelName).url.depimmiconfirmface;
			const title = this.$puiI18n.t('arrimmiconfirmface.title');
			const zoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid,
				date: modalData.date,
				zoneid: zoneId
			};
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}

						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkarrimmiconfirmclear(modalData) {
			const urlController = this.$store.getters.getModelByName(this.modelName).url.arrimmiconfirmclear;
			const title = this.$puiI18n.t('arrimmiconfirmclear.title');
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid
			};
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}

						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkdepimmiconfirmclear(modalData) {
			const urlController = this.$store.getters.getModelByName(this.modelName).url.depimmiconfirmclear;
			const title = this.$puiI18n.t('depimmiconfirmclear.title');
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid
			};
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}

						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOknotifyAllDocsSubmitted(modalData) {
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid,
				remarks: modalData.remarks,
				immigration: this.checkboxGroup.immigration,
				customs: this.checkboxGroup.customs,
				harbour: this.checkboxGroup.harbourMaster
			};
			const urlController = this.$store.getters.getModelByName(this.modelPortcalls).url.notifyAllDocsSubmitted;
			const title = this.$puiI18n.t('notifyAllDocsSubmitted.title');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,

					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkdepnotifyAllDocsSubmitted(modalData) {
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid,
				remarks: modalData.remarks,
				immigration: this.checkboxGroup.immigration,
				customs: this.checkboxGroup.customs,
				harbour: this.checkboxGroup.harbourMaster
			};
			const urlController = this.$store.getters.getModelByName(this.modelPortcalls).url.depnotifyAllDocsSubmitted;
			const title = this.$puiI18n.t('depnotifyAllDocsSubmitted.title');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,

					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkArrImmigrationValidateDocs(modalData) {
			const urlController = this.$store.getters.getModelByName(this.modelPortcalls).url.arrvalidatedocs;
			const title = this.$puiI18n.t('arrvalidatedocs.title');
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid
			};

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.model);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.model);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}

						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkDepImmigrationValidateDocs(modalData) {
			const urlController = this.$store.getters.getModelByName(this.modelPortcalls).url.depvalidatedocs;
			const title = this.$puiI18n.t('depvalidatedocs.title');
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid
			};

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.model);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.model);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}

						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkArrImmigrationRejectDocs(modalData) {
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid,
				remarks: modalData.remarks
			};
			const urlController = this.$store.getters.getModelByName(this.modelPortcalls).url.arrimmirejectdocs;

			const title = this.$puiI18n.t('arrimmirejectdocs.title');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,

					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkDepImmigrationRejectDocs(modalData) {
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid,
				remarks: modalData.remarks
			};
			const urlController = this.$store.getters.getModelByName(this.modelPortcalls).url.depimmirejectdocs;

			const title = this.$puiI18n.t('depimmirejectdocs.title');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,

					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onShownotifyAllDocsSubmitted(modalData) {
			this.resetCheckboxes();
			this.fetchDocumentStatuses(modalData.pk, 'arr');
		},
		onShowdepnotifyAllDocsSubmitted(modalData) {
			this.resetCheckboxes();
			this.fetchDocumentStatuses(modalData.pk, 'dep');
		},
		onOkarrcustacceptdocs(modalData) {
			const urlController = this.$store.getters.getModelByName(this.modelName).url.arrcustacceptdocs;
			const title = this.$puiI18n.t('arrcustacceptdocs.title');
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid
			};
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}

						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkdepcustacceptdocs(modalData) {
			const urlController = this.$store.getters.getModelByName(this.modelName).url.depcustacceptdocs;
			const title = this.$puiI18n.t('depcustacceptdocs.title');
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid
			};
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}

						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkArrCustomRejectDocs(modalData) {
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid,
				remarks: modalData.remarks
			};
			const urlController = this.$store.getters.getModelByName(this.modelName).url.arrcustrejectdocs;

			const title = this.$puiI18n.t('arrcustrejectdocs.title');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,

					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkDepCustomRejectDocs(modalData) {
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid,
				remarks: modalData.remarks
			};
			const urlController = this.$store.getters.getModelByName(this.modelName).url.depcustrejectdocs;

			const title = this.$puiI18n.t('depcustrejectdocs.title');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,

					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkCustomRejectBerth(modalData) {
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid,
				remarks: modalData.remarks
			};
			const urlController = this.$store.getters.getModelByName(this.modelName).url.custrejectberth;

			const title = this.$puiI18n.t('custrejectberth.title');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,

					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkArrHarborRejectDocs(modalData) {
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid,
				remarks: modalData.remarks
			};
			const urlController = this.$store.getters.getModelByName(this.modelName).url.arrharborrejectdocs;

			const title = this.$puiI18n.t('arrharborrejectdocs.title');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,

					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkDepHarborRejectDocs(modalData) {
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid,
				remarks: modalData.remarks
			};
			const urlController = this.$store.getters.getModelByName(this.modelName).url.depharborrejectdocs;

			const title = this.$puiI18n.t('depharborrejectdocs.title');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,

					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkarrharborconfirmclear(modalData) {
			const urlController = this.$store.getters.getModelByName(this.modelName).url.arrharborconfirmclear;
			const title = this.$puiI18n.t('arrharborconfirmclear.title');
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid
			};
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}

						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkdepharborconfirmclear(modalData) {
			const urlController = this.$store.getters.getModelByName(this.modelName).url.depharborconfirmclear;
			const title = this.$puiI18n.t('depharborconfirmclear.title');
			const zoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid,
				zoneid: zoneId
			};
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}

						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkdepharborvalidatedocs(modalData) {
			const urlController = this.$store.getters.getModelByName(this.modelName).url.depharborvalidatedocs;
			const title = this.$puiI18n.t('depharborvalidatedocs.title');
			const params = {
				model: this.modelName,
				portcallid: modalData.headerPk.portcallid
			};
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}

						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		resetCheckboxes() {
			this.checkboxGroup.immigration = false;
			this.checkboxGroup.customs = false;
			this.checkboxGroup.harbourMaster = false;
			this.checkimmigration = true;
			this.checkcustoms = true;
			this.checkharbour = true;
		},
		fetchDocumentStatuses(portcallid, prefix) {
			const opts = {
				model: 'portcallclearancesstatus',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'portcallid',
							op: 'eq',
							data: portcallid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				var imigrations = response.data.data[0][`${prefix}immigrationstatus`];
				var custom = response.data.data[0][`${prefix}customsstatus`];
				var harbour = response.data.data[0][`${prefix}harbourmasterstatus`];

				if (imigrations == 'DOC_PENDING' || imigrations == 'DOC_REJECTED') {
					this.checkimmigration = false;
				}
				if (custom == 'DOC_PENDING' || custom == 'DOC_REJECTED') {
					this.checkcustoms = false;
				}
				if (harbour == 'DOC_PENDING' || harbour == 'DOC_REJECTED') {
					this.checkharbour = false;
				}
			});
		}
	},
	computed: {
		getTitleAutoritiestonotify() {
			return this.$t('modal.alldocumentsSubmitted.titleauthories');
		}
	},
	mounted() {
		this.$puiEvents.$on('docsStatusChanged', this.fetchDocumentStatuses);
	}
};
</script>
